import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"

import { StaticImage } from 'gatsby-plugin-image'

const ImageContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
  padding: 1.45rem 1.0875rem;
`

export function Logo() {
  return <StaticImage 
    src="../images/_msc2 - Large.png" 
    alt="MSC Logo"
    placeholder="blurred"
    layout="constrained"
    />
  }

const IndexPage = () => (
  <Layout>
    <SEO title="micro-service consulting" keywords={[`consulting`, `tech`, `development`, `business`, `digital transformation`]} />
    <ImageContainer>
      <Logo></Logo>
    </ImageContainer>
    <LandingBio />
  </Layout>
)

export default IndexPage
